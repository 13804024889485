import { useState, useEffect } from "react";
import * as contentful from "contentful";

const newsClient = contentful.createClient({
  space: "7tigb0siwwyk",
  environment: "noticias", // defaults to 'master' if not set
  accessToken: "EOpqpOGu_0DHSAtQXdJvKuDIniOrjPLKcwOYrz2NaGI",
});

function Noticias() {
  const [news, setNews] = useState([]);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

  useEffect(() => {
    // Fetch data from Contentful
    newsClient.getEntries({ content_type: "noticias" }).then((response) => {
      // Extract relevant data from the response
      const newsData = response.items.map((item) => ({
        id: item.sys.id,
        titulo: item.fields.titulo,
        preview: item.fields.preview,
        imagenUrl: item.fields.imagen.fields.file.url,
        link: item.fields.link,
      }));
      // Update the state with the fetched news
      setNews(newsData);
    });
  }, []);

  const currentNews =
    news.length > 0 ? news[currentNewsIndex % news.length] : "";

  const nextSlide = () => {
    setCurrentNewsIndex((prevIndex) => {
      console.log("Next Index:", prevIndex + 1);
      return prevIndex + 1;
    });
  };

  const prevSlide = () => {
    setCurrentNewsIndex((prevIndex) => prevIndex - 1);
  };
  return (
    <section id="noticias" className="bg-white">
      <div className="flex justify-center">
        <h1 className="text-4xl lg:text-9xl text-slate-800 bg-white">
          Noticias
        </h1>
      </div>
      <div className="carousel w-full h-fit overflow-y-hidden py-20">
        <div className=" relative w-full">
          <div className="flex justify-center">
            <div className="card card-compact bg-white shadow-xl w-[75vw]">
              <figure>
                <img
                  src={currentNews.imagenUrl}
                  className="w-full h-full"
                  alt="Noticia"
                />
              </figure>
              <div className="card-body text-slate-800">
                <h2 className="card-title">{currentNews.titulo}</h2>
                <p>{currentNews.preview}</p>
                <div className="card-actions justify-end">
                  <a href={currentNews.link} className="btn btn-primary">
                    Ver Noticia Completa
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-full flex flex-col justify-center lg:translate-y-10 items-center text-2xl lg:text-5xl text-center text-slate-800"></div>
          <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <button
              onClick={prevSlide}
              className="btn btn-circle bg-transparent"
            >
              ❮
            </button>
            <button
              onClick={nextSlide}
              className="btn btn-circle bg-transparent"
            >
              ❯
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Noticias;
