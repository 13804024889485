import { useState, useEffect } from "react";
import * as contentful from "contentful";

const testimoniosClient = contentful.createClient({
  space: "7tigb0siwwyk",
  environment: "master", // defaults to 'master' if not set
  accessToken: "EOpqpOGu_0DHSAtQXdJvKuDIniOrjPLKcwOYrz2NaGI",
});

function Testimonios() {
  const [quotes, setQuotes] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    testimoniosClient
      .getEntries()
      .then((response) => {
        console.log(response);
        const fetchedQuotes = response.items.map((item) => {
          let imageUrl = "";
          if (item.fields.perfil) {
            imageUrl = item.fields.perfil.fields.file.url;
          }
          setImageUrls((prevImageUrls) => [...prevImageUrls, imageUrl]); // Store image URL
          return item.fields.citas.content.map((paragraph) => {
            return paragraph.content.map((node, index) => {
              // Handle different types of content nodes
              if (node.nodeType === "text") {
                return (
                  <span className="w-3/4" key={index}>
                    {node.value}
                  </span>
                );
              } else if (node.nodeType === "paragraph") {
                return (
                  <p key={index}>{node.content.map((child) => child.value)}</p>
                );
              } else if (node.nodeType === "heading-1") {
                return (
                  <h1 key={index}>
                    {node.content.map((child) => child.value)}
                  </h1>
                );
              } else if (node.nodeType === "hyperlink") {
                const uri = node.data.uri;
                const videoPreview = (
                  <iframe
                    key={index}
                    width="560"
                    height="315"
                    src={uri}
                    title="Testimonio Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                );
                return videoPreview;
              }
              return null;
            });
          });
        });
        setQuotes(fetchedQuotes);
      })
      .catch(console.error);
  }, []);

  const currentQuote =
    quotes.length > 0 ? quotes[currentIndex % quotes.length] : "";

  const currentImageUrl =
    imageUrls.length > 0 ? imageUrls[currentIndex % imageUrls.length] : "";

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  return (
    <section
      id="testimonios"
      className="bg-white flex flex-col justify-center items-center"
    >
      <h1 className="text-4xl lg:text-9xl text-slate-800">Testimonios</h1>
      <div className="carousel w-full h-fit overflow-y-hidden py-20">
        <div className="carousel-item relative w-full">
          <div className="w-full h-full flex flex-col justify-center lg:translate-y-10 items-center text-xl lg:text-3xl text-center text-slate-800 w-3/4">
            {currentImageUrl && (
              <img
                className="rounded-full w-32 h-32"
                alt="testimonio-autor"
                src={`https:${currentImageUrl}`}
              />
            )}
            {currentQuote}
          </div>
          <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <button
              onClick={prevSlide}
              className="btn btn-circle bg-transparent"
            >
              ❮
            </button>
            <button
              onClick={nextSlide}
              className="btn btn-circle bg-transparent"
            >
              ❯
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonios;
