import "./App.css";
import Contacto from "./Component/Contacto";
import Header from "./Component/Header";
import Info from "./Component/Info";
import Mision from "./Component/Mision";
import Navbar from "./Component/Navbar";
import Noticias from "./Component/Noticias";
import Sumate from "./Component/Sumate";
import Testimonios from "./Component/Testimonios";

function App() {
  return (
    <body className="bg-white h-screen">
      <Navbar />
      <Header />
      <Info />
      <Mision />
      <Sumate />
      <Testimonios />
      <Noticias />
      <Contacto />
    </body>
  );
}

export default App;
