import icon from "../assets/icon-gracias.png";
import { Link } from "react-router-dom";

function Gracias() {
  return (
    <section className="bg-[#e5251f] h-screen flex items-center justify-center">
      <div className="card card-side bg-white w-3/4 shadow-xl px-2 py-2">
        <figure>
          <img src={icon} alt="logo" />
        </figure>
        <div className="w-1/2 card-body">
          <h2 className="card-title text-slate-800">Muchas Gracias!</h2>
          <p className="text-sm sm:text-3xl text-slate-800">
            Muchas gracias por Sumarte al Movimiento Ciudadano Con mi Salud No,
            te enviamos un email para estar en contacto. Nos vemos .
          </p>
          <div className="card-actions justify-end">
            <Link to="/" className="btn btn-primary">
              Volver a página principal
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gracias;
