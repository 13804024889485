import { useState } from "react";

const comunasPorRegion = {
  "Arica y Parinacota": ["Arica", "Camarones", "General Lagos", "Putre"],
  Tarapacá: [
    "Iquique",
    "Alto Hospicio",
    "Camiña",
    "Colchane",
    "Huara",
    "Pica",
    "Pozo Almonte",
  ],
  Antofagasta: [
    "Antofagasta",
    "Calama",
    "María Elena",
    "Mejillones",
    "Ollagüe",
    "San Pedro de Atacama",
    "Sierra Gorda",
    "Taltal",
    "Tocopilla",
  ],
  Atacama: [
    "Copiapó",
    "Caldera",
    "Chañaral",
    "Diego de Almagro",
    "Freirina",
    "Huasco",
    "Tierra Amarilla",
    "Vallenar",
  ],
  Coquimbo: [
    "La Serena",
    "Coquimbo",
    "Andacollo",
    "Canela",
    "Combarbalá",
    "Illapel",
    "Los Vilos",
    "Monte Patria",
    "Ovalle",
    "Paiguano",
    "Punitaqui",
    "Río Hurtado",
    "Salamanca",
    "Vicuña",
  ],
  Valparaíso: [
    "Valparaíso",
    "Casablanca",
    "Concón",
    "Juan Fernández",
    "Puchuncaví",
    "Quilpué",
    "Quintero",
    "Villa Alemana",
    "Viña del Mar",
    "Isla de Pascua",
    "Los Andes",
    "Calle Larga",
    "Rinconada",
    "San Esteban",
    "La Ligua",
    "Cabildo",
    "Papudo",
    "Petorca",
    "Zapallar",
    "Quillota",
    "Calera",
    "Hijuelas",
    "La Cruz",
    "Nogales",
    "San Antonio",
    "Algarrobo",
    "Cartagena",
    "El Quisco",
    "El Tabo",
    "Santo Domingo",
    "San Felipe",
    "Catemu",
    "Llaillay",
    "Panquehue",
    "Putaendo",
    "Santa María",
    "Quilpué",
    "Limache",
    "Olmué",
  ],
  "Región del Libertador General Bernardo O'Higgins": [
    "Rancagua",
    "Codegua",
    "Coinco",
    "Coltauco",
    "Doñihue",
    "Graneros",
    "Las Cabras",
    "Machalí",
    "Malloa",
    "Mostazal",
    "Olivar",
    "Peumo",
    "Pichidegua",
    "Quinta de Tilcoco",
    "Rengo",
    "Requínoa",
    "San Vicente",
    "Pichilemu",
    "La Estrella",
    "Litueche",
    "Marchihue",
    "Navidad",
    "Paredones",
    "San Fernando",
    "Chépica",
    "Chimbarongo",
    "Lolol",
    "Nancagua",
    "Palmilla",
    "Peralillo",
    "Placilla",
    "Pumanque",
    "Santa Cruz",
  ],
  "Región del Maule": [
    "Talca",
    "Constitución",
    "Curepto",
    "Empedrado",
    "Maule",
    "Pelarco",
    "Pencahue",
    "Río Claro",
    "San Clemente",
    "San Rafael",
    "Cauquenes",
    "Chanco",
    "Pelluhue",
    "Curicó",
    "Hualañé",
    "Licantén",
    "Molina",
    "Rauco",
    "Romeral",
    "Sagrada Familia",
    "Teno",
    "Vichuquén",
    "Linares",
    "Colbún",
    "Longaví",
    "Parral",
    "Retiro",
    "San Javier",
    "Villa Alegre",
    "Yerbas Buenas",
  ],
  "Región del Biobío": [
    "Concepción",
    "Coronel",
    "Chiguayante",
    "Florida",
    "Hualqui",
    "Lota",
    "Penco",
    "San Pedro de la Paz",
    "Santa Juana",
    "Talcahuano",
    "Tomé",
    "Hualpén",
    "Lebu",
    "Arauco",
    "Cañete",
    "Contulmo",
    "Curanilahue",
    "Los Álamos",
    "Tirúa",
    "Los Ángeles",
    "Antuco",
    "Cabrero",
    "Laja",
    "Mulchén",
    "Nacimiento",
    "Negrete",
    "Quilaco",
    "Quilleco",
    "San Rosendo",
    "Santa Bárbara",
    "Tucapel",
    "Yumbel",
    "Alto Biobío",
    "Chillán",
    "Bulnes",
    "Cobquecura",
    "Coelemu",
    "Coihueco",
    "Chillán Viejo",
    "El Carmen",
    "Ninhue",
    "Ñiquén",
    "Pemuco",
    "Pinto",
    "Portezuelo",
    "Quillón",
    "Quirihue",
    "Ránquil",
    "San Carlos",
    "San Fabián",
    "San Ignacio",
    "San Nicolás",
    "Treguaco",
    "Yungay",
  ],
  "Región de la Araucanía": [
    "Temuco",
    "Carahue",
    "Cunco",
    "Curarrehue",
    "Freire",
    "Galvarino",
    "Gorbea",
    "Lautaro",
    "Loncoche",
    "Melipeuco",
    "Nueva Imperial",
    "Padre las Casas",
    "Perquenco",
    "Pitrufquén",
    "Pucón",
    "Saavedra",
    "Teodoro Schmidt",
    "Toltén",
    "Vilcún",
    "Villarrica",
    "Cholchol",
    "Angol",
    "Collipulli",
    "Curacautín",
    "Ercilla",
    "Lonquimay",
    "Los Sauces",
    "Lumaco",
    "Purén",
    "Renaico",
    "Traiguén",
    "Victoria",
  ],
  "Región de Los Ríos": [
    "Valdivia",
    "Corral",
    "Lanco",
    "Los Lagos",
    "Máfil",
    "Mariquina",
    "Paillaco",
    "Panguipulli",
    "La Unión",
    "Futrono",
    "Lago Ranco",
    "Río Bueno",
  ],
  "Región de Los Lagos": [
    "Puerto Montt",
    "Calbuco",
    "Cochamó",
    "Fresia",
    "Frutillar",
    "Los Muermos",
    "Llanquihue",
    "Maullín",
    "Puerto Varas",
    "Castro",
    "Ancud",
    "Chonchi",
    "Curaco de Vélez",
    "Dalcahue",
    "Puqueldón",
    "Queilén",
    "Quellón",
    "Quemchi",
    "Quinchao",
    "Osorno",
    "Puerto Octay",
    "Purranque",
    "Puyehue",
    "Río Negro",
    "San Juan de la Costa",
    "San Pablo",
    "Chaitén",
    "Futaleufú",
    "Hualaihué",
    "Palena",
  ],
  "Región de Aysén del General Carlos Ibáñez del Campo": [
    "Coyhaique",
    "Lago Verde",
    "Aysén",
    "Cisnes",
    "Guaitecas",
    "Cochrane",
    "O’Higgins",
    "Tortel",
    "Chile Chico",
    "Río Ibáñez",
  ],
  "Región de Magallanes y de la Antártica Chilena": [
    "Punta Arenas",
    "Laguna Blanca",
    "Río Verde",
    "San Gregorio",
    "Cabo de Hornos (Ex Navarino)",
    "Antártica",
    "Porvenir",
    "Primavera",
    "Timaukel",
    "Natales",
    "Torres del Paine",
  ],
  "Región Metropolitana": [
    "Santiago",
    "Cerrillos",
    "Cerro Navia",
    "Conchalí",
    "El Bosque",
    "Estación Central",
    "Huechuraba",
    "Independencia",
    "La Cisterna",
    "La Florida",
    "La Granja",
    "La Pintana",
    "La Reina",
    "Las Condes",
    "Lo Barnechea",
    "Lo Espejo",
    "Lo Prado",
    "Macul",
    "Maipú",
    "Ñuñoa",
    "Pedro Aguirre Cerda",
    "Peñalolén",
    "Providencia",
    "Pudahuel",
    "Quilicura",
    "Quinta Normal",
    "Recoleta",
    "Renca",
    "San Joaquín",
    "San Miguel",
    "San Ramón",
    "Vitacura",
    "Puente Alto",
    "Pirque",
    "San José de Maipo",
    "Colina",
    "Lampa",
    "Tiltil",
    "San Bernardo",
    "Buin",
    "Calera de Tango",
    "Paine",
    "Melipilla",
    "Alhué",
    "Curacaví",
    "María Pinto",
    "San Pedro",
    "Talagante",
    "El Monte",
    "Isla de Maipo",
    "Padre Hurtado",
    "Peñaflor",
  ],
};

function Sumate() {
  const [regionSeleccionada, setRegionSeleccionada] = useState("");
  const [comunas, setComunas] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const checkbox = document.getElementById("checkbox");
    if (!checkbox.checked) {
      alert("Por favor leer documento antes de firmar!");
      return;
    }

    // Get form data
    const formData = new FormData(e.target);

    try {
      // Send form data to Google Sheets using fetch or axios
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzDb0uOKejkHwdxb7LIBuA2fgQZJfAPZYWa_36d-bjBCl5_HgZfyKtjXtT2O1A4zY_c/exec",
        {
          method: "POST",
          body: formData,
        },
      );

      // Check if request was successful
      if (response.ok) {
        // Redirect or perform any other actions after successful form submission
        window.location.href = "/gracias";
      } else {
        // Handle error if form submission fails
        console.error("Failed to submit form data to Google Sheets");
      }
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error("Error submitting form data:", error);
    }
  };

  const handleRegionChange = (e) => {
    const region = e.target.value;
    setRegionSeleccionada(region);
    setComunas(comunasPorRegion[region] || []);
  };

  return (
    <div id="sumate" className="bg-white px-4 pt-4 lg:p-0">
      <div className="flex items-center justify-center">
        <h1 className="text-slate-700 text-6xl lg:text-9xl">Súmate</h1>
      </div>
      <form
        name="contact-form"
        id="form"
        method="post"
        onSubmit={handleSubmit}
        className="mt-20 w-full flex flex-col items-center justify-center"
      >
        <div className="grid gap-6 mb-6 md:grid-cols-2">
          <div>
            <label
              for="nombres"
              className="block mb-2 text-sm font-medium text-slate-700"
            >
              Nombres
            </label>
            <input
              type="text"
              name="nombres"
              className="bg-white border border-gray-300 text-slate-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Nombre Nombre"
              required
            />
          </div>
          <div>
            <label
              for="apellidos"
              className="block mb-2 text-sm font-medium text-slate-700"
            >
              Apellidos
            </label>
            <input
              type="text"
              name="apellidos"
              className="bg-white border border-gray-300 text-slate-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Apellido Apellido"
              required
            />
          </div>
          <div>
            <label
              for="rut"
              className="block mb-2 text-sm font-medium text-slate-700"
            >
              RUT
            </label>
            <input
              type="text"
              name="rut"
              className="bg-white border border-gray-300 text-slate-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="12.345.678-9"
              required
            />
          </div>
          <div>
            <label
              for="email"
              className="block mb-2 text-sm font-medium text-slate-700"
            >
              E-mail
            </label>
            <input
              type="email"
              name="email"
              className="bg-white border border-gray-300 text-slate-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="usuario@dominio.com"
              required
            />
          </div>
          <div>
            <label
              for="tel"
              className="block mb-2 text-sm font-medium text-slate-700"
            >
              Teléfono
            </label>
            <input
              name="tel"
              className="bg-white border border-gray-300 text-slate-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="+56 X XXXX XXXX"
              required
            />
          </div>
          <div>
            <label
              for="region"
              className="block mb-2 text-sm font-medium text-slate-700"
            >
              Región
            </label>
            <select
              type="region"
              name="region"
              className="bg-white border border-gray-300 text-slate-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={regionSeleccionada}
              onChange={handleRegionChange}
            >
              {Object.keys(comunasPorRegion).map((region) => (
                <option key={region} value={region}>
                  {region}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="comuna"
              className="block mb-2 text-sm font-medium text-slate-700"
            >
              Comuna
            </label>
            <select
              type="comuna"
              name="comuna"
              className="bg-white border border-gray-300 text-slate-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              {comunas.map((comuna) => (
                <option key={comuna} value={comuna}>
                  {comuna}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              for="enfermedad"
              className="block mb-2 text-sm font-medium text-slate-700"
            >
              ¿Tienes enfermedades preexistentes?
            </label>
            <select
              type="enfermedad"
              name="enfermedad"
              className="bg-white border border-gray-300 text-slate-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value="si">SI</option>
              <option value="no">NO</option>
            </select>
          </div>
          <div>
            <label
              for="seguro"
              className="block mb-2 text-sm font-medium text-slate-700"
            >
              ¿Qué aseguradora de salud tienes?
            </label>
            <select
              type="seguro"
              name="seguro"
              className="bg-white border border-gray-300 text-slate-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value="fonasa">Fonasa</option>
              <option value="isapre">Isapre</option>
              <option value="capredena">Capredena</option>
              <option value="ninguna">Ninguna</option>
            </select>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center px-4 lg:px-0 py-10">
          <label
            className="block mb-2 text-xl font-bold text-slate-700"
            for="submit"
          >
            Al llenar esta ficha,te sumas a las acciones legales necesarias para
            resguardar nuestros derechos humanos en la salud
          </label>
          <div className="flex flex-row pb-10">
            <input type="checkbox" id="checkbox" />
            <span className="text-slate-800">
              Leí el{" "}
              <a
                className="text-blue-700 underline"
                href="https://drive.google.com/file/d/1_MFeBivif0HNrV8pgNJeAmSZjbXLVsc1/view?usp=sharing"
              >
                documento de información
              </a>{" "}
              antes de firmar
            </span>
          </div>
          <button
            type="submit"
            value="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-4xl w-full sm:w-auto px-5 py-2.5 text-center"
          >
            Firmar y Enviar
          </button>
        </div>
      </form>
    </div>
  );
}

export default Sumate;
