import equipo from "../assets/equipo.png";

function Info() {
  return (
    <section
      id="info"
      className="bg-white py-4 lg:p-0 flex flex-col lg:flex-row gap-y-2 items-center lg:h-screen"
    >
      <div className="w-[80%] lg:w-1/2 lg:pl-5 lg:pt-10">
        <h1 className="text-5xl lg:text-8xl text-slate-800 text-center">
          ¿Quienes Somos?
        </h1>
        <p className="text-base lg:text-2xl text-slate-800 pt-5 lg:pr-20">
          Somos un Movimiento Ciudadano de personas vulnerables frente a un
          sistema de salud que va en caída libre. Nos sentimos desprotegidos en
          los derechos humanos más básicos:
          <b>Protección a la Salud, a la Dignidad y a la Vida. </b> Y todo ello
          porque no estamos sanos; porque tenemos preexistencias, porque somos
          adultos mayores o porque tuvimos la mala suerte de nacer o adquirir
          enfermedades nuevas no reconocidas por el Sistema.
        </p>
      </div>
      <div className="w-[80%]">
        <img src={equipo} />
      </div>
    </section>
  );
}

export default Info;
