function Mision() {
  return (
    <section id="mision" className="bg-white">
      <div className="carousel w-full h-fit overflow-y-hidden">
        <div id="slide1" className="carousel-item  relative w-full">
          <div className="w-full h-full bg-[#e5251f] flex flex-col  justify-center lg:translate-y-10 items-center">
            <h1 className="text-white font-bold text-4xl lg:text-9xl">
              Misión
            </h1>
            <p className="text-2xl lg:text-5xl text-center text-white w-3/4 translate-y-5">
              Nuestra misión es ser portadores de esas voces silenciosas que
              sufren día a día por no tener una protección oportuna a su salud.
              Es promover el acceso al cuidado de los más vulnerables
              mostrándole a los actores del Sistema que una renovación no sólo
              es necesaria, sino urgente.
            </p>
          </div>
          <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#slide4" className="btn btn-circle">
              ❮
            </a>
            <a href="#slide2" className="btn btn-circle">
              ❯
            </a>
          </div>
        </div>
        <div id="slide2" className="carousel-item relative w-full">
          <div className="w-full h-full bg-[#e5251f] flex flex-col justify-center lg:translate-y-10 items-center">
            <h1 className="text-white font-bold text-4xl lg:text-9xl">
              Visión
            </h1>
            <p className="text-2xl lg:text-5xl text-center text-white w-3/4 translate-y-5">
              Nuestra visión es ser referentes cuando se trata de la Protección
              a la Salud de los más vulnerables, promoviendo e influenciando,
              desde una óptica ciudadana objetiva, políticas públicas que se
              adecúen a las reales necesidades de los habitantes.
            </p>
          </div>
          <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#slide1" className="btn btn-circle">
              ❮
            </a>
            <a href="#slide3" className="btn btn-circle">
              ❯
            </a>
          </div>
        </div>
        <div id="slide3" className="carousel-item relative w-full">
          <div className="w-full h-full bg-[#e5251f] flex flex-col justify-center lg:translate-y-10 items-center">
            <h1 className="text-white font-bold text-center text-4xl lg:text-9xl">
              Objetivos específicos
            </h1>
            <ul className="flex flex-col justify-center items-center translate-y-10">
              <li className="list-disc text-xl lg:text-4xl text-white w-3/4 pb-4 lg:pb-10">
                Promover una solución definitiva al caso de las preexistencias
                en el Sistema Privado
              </li>
              <li className="list-disc text-xl lg:text-4xl text-white w-3/4 pb-4 lg:pb-10">
                Promover un equilibrio entre el sistema privado y el sistema
                público que proteja a los Adultos Mayores
              </li>
              <li className="list-disc text-xl lg:text-4xl text-white w-3/4 pb-4 lg:pb-10">
                Eliminar las barreras burocráticas que impiden una atención
                eficaz y oportuna a enfermedades nuevas
              </li>
              <li className="list-disc text-xl lg:text-4xl text-white w-3/4 pb-4 lg:pb-10">
                Proteger el derecho a elección del sistema de salud sin
                presiones financieras ni ataduras
              </li>
              <li className="list-disc text-xl lg:text-4xl text-white w-3/4 pb-4 lg:pb-10">
                Robustecer el pilar solidario para los más vulnerables
              </li>
            </ul>
          </div>
          <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#slide2" className="btn btn-circle">
              ❮
            </a>
            <a href="#slide4" className="btn btn-circle">
              ❯
            </a>
          </div>
        </div>
        <div id="slide4" className="carousel-item relative w-full">
          <div className="w-full h-full bg-[#e5251f] flex flex-col justify-center lg:translate-y-10 items-center">
            <h1 className="text-white font-bold text-4xl text-center lg:text-9xl">
              Declaración de Principios
            </h1>
            <p className="text-xl lg:text-3xl text-white w-3/4 my-10">
              Somos ciudadanos que tenemos preexistencia de salud y nos vemos
              vulnerados en nuestro derecho humano a una atención medica digna.
              La inestabilidad del sistema público y privado de la salud está en
              riesgo de quiebre, y en consecuencia la cobertura real, oportuna,
              eficiente y eficaz. <br /> <br />
              Creemos que el Estado de Chile debe defender mi libertad de
              elección, a qué aseguradora de salud quiero adherir para cuidarme
              y tener mejor calidad de vida, con los desafíos de salud que mi
              cuerpo le toca enfrentar. <br /> <br />
              Un Movimiento Ciudadano es lo que ha querido organizar Carolina
              Plaza, concejala de la Municipalidad de Providencia. Ella ha
              tenido dos tratamientos de cáncer: uno de tiroides y el segundo de
              mama, y ha visto, como la inestabilidad política y en la salud, se
              ha agudizado post 18-O, lo que ha complicado las atenciones y
              tratamientos de quienes tienen enfermedades crónicas,
              prexistencias y/o enfermedades no transmisibles que vulnera la
              calidad de vida.
            </p>
          </div>
          <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#slide3" className="btn btn-circle">
              ❮
            </a>
            <a href="#slide5" className="btn btn-circle">
              ❯
            </a>
          </div>
        </div>
        <div id="slide5" className="carousel-item relative w-full">
          <div className="w-full h-full bg-[#e5251f] flex flex-col justify-center lg:translate-y-10 items-center">
            <h1 className="text-white font-bold text-4xl lg:text-9xl">
              ¿Qué queremos?
            </h1>
            <p className="text-xl lg:text-5xl text-center text-white w-3/4 my-10">
              No queremos ser discriminados por nuestra historia médica. Estamos
              viviendo una situación de inestabilidad en todo el sistema de
              salud. Por eso, necesitamos un marco jurídico que nos proteja,
              cambiando la visión que se quiere imponer y que los tres poderes
              del estado tomen consciencia de la libertad real y oportuna de
              optar por donde y como queremos tratar nuestras enfermedades o
              preexistencia. <br /> <br /> Por es: (los puntos del escrito)
            </p>
          </div>
          <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#slide4" className="btn btn-circle bg-transparent">
              ❮
            </a>
            <a href="#slide1" className="btn btn-circle bg-transparent">
              ❯
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Mision;
