import header from "../assets/header.png";
function Header() {
  return (
    <header
      className="w-full h-full flex flex-col lg:flex-row items-center justify-center lg:justify-end"
      style={{
        backgroundImage: `url(${header})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section className="w-[80%] mt-12 lg:m-0 lg:w-1/4 max-h-full lg:translate-y-10 lg:-translate-x-20">
        <h1 className="header-title text-4xl lg:text-7xl font-bold text-white text-center">
          MI VIDA ESTÁ PRIMERO
        </h1>
        <p className="header-subtitle text-xl lg:text-2xl text-white pt-5 font-light text-center">
          Nuestra salud pende de un hilo. En ninguna propuesta del nuevo sistema
          se contempla qué va a pasar con las preexistencias, y si nos obligan a
          todos a pasarnos al sistema público la situación será devastadora.
          <br />
          <span className="font-bold">¡Basta de jugar con nuestra Salud!</span>
        </p>
      </section>
    </header>
  );
}

export default Header;
